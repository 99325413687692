
.parts{
    position: absolute;
}


.myProjects{
    position: relative;
    left: 30px;
    top: 200px;
    font-size: 50px;
    font-family: "Vibur", sans-serif; 
    font-size: 120px;
    font-weight: 700;
    animation: flicker 1.5s infinite alternate;     

}

@keyframes flicker {
    
    0%, 18%, 22%, 25%, 53%, 57%, 100% {
  
        text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 40px #5e43f3,
        0 0 80px #5e43f3,
        0 0 90px #5e43f3,
        0 0 100px #5e43f3,
        0 0 150px #5e43f3;
    
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
    }    
  }

.my-anim{
    position: relative;
    left: 5vw;
}

#part1{
    height: 4.5rem;
    left: 3rem;
    top:23rem;
}

@keyframes join1 {
    0%{
        left: 3rem;
        top:23rem;
    }
    4%, 8%, 12%, 16%, 20%, 24%, 28%, 32%, 36%, 40%, 44%, 48%, 52%, 56%, 60%, 64%, 68%,75%,85%,95%{
        transform: rotate(20deg);
    }
    2%, 6%, 10%, 14%, 18%, 22%, 26%, 30%, 34%, 38%, 42%, 46%, 50%, 54%, 58%, 62%, 66%, 70%,80%,90%{
        transform: rotate(0deg);
    }
    71%{
        left: 3rem;
        top:23rem;
        transform: rotate(20deg);
    }

    100%{
        transform: rotate(0deg);
        left: 4.5rem;
        top: 0.7rem;
    }
}

#part2{
    height: 7.5rem;
    top: 7.4rem;
    left: 18.2rem;
}



@keyframes join2 {
    /* 0%{
        left: 80rem;
        top:12rem;
    }
    10%,20%,30%{
        transform: rotate(20deg);
    }
    5%,15%,25%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(0deg);
        top: 7.4rem;
        left: 18.2rem;
    } */
    0%{
        top: 7.4rem;
        left: 18.2rem;
        transform: rotate(-85deg);
        transform-origin: top;
    }
}

#part3{
    height: 9.8rem;
    left: 50rem;
    top:30rem;
    transform: rotateX(80deg);

}

@keyframes join3 {
    0%{
        left: 50rem;
        top:30rem;
        transform: (80deg);
    }
    4%, 8%, 12%, 16%, 20%, 24%, 28%{
        transform: rotate(20deg) rotateX(80deg);
    }
    2%, 6%, 10%, 14%, 18%, 22%, 26%, 30%{
        transform: rotate(0deg)rotateX(80deg);
    }
    50%{
        transform: rotateX(0deg);
        transform-origin: bottom;
    }
    71%{
        left: 50rem;
        top:30rem;
        transform: rotate(20deg) rotateZ(0deg);
    }
    90%{
        height: 15.8rem;
    }
    95%{
        top: 14.8rem;
        left: 13.3rem;
        transform:  rotateZ(-360deg);
    }
    100%{
        height: 9.8rem;
        top: 14.8rem;
        left: 13.3rem;
        transform: rotateX(0deg)rotateZ(-360deg);
    }
}

#part4{
    height: 7.3rem;
    left: 50rem;
    top:1rem;
}
@keyframes join4 {
    0%{
        left: 50rem;
        top:1rem;
    }
    4%, 8%, 12%, 16%, 20%, 24%, 28%, 32%, 36%, 40%, 44%, 48%, 52%, 56%, 60%, 64%, 68%,75%,85%,95%{
        transform: rotate(20deg);
    }
    2%, 6%, 10%, 14%, 18%, 22%, 26%, 30%, 34%, 38%, 42%, 46%, 50%, 54%, 58%, 62%, 66%, 70%,80%,90%{
        transform: rotate(0deg);
    }
    71%{
        left: 50rem;
        top:1rem;
        transform: rotate(20deg);
    }
    100%{
        transform: rotate(0deg);
        top: 7.7rem;
        left: 13.9rem;
    }
}

#part5{
    height: 4.5rem;
    left:40rem;
    top:25rem;
}


@keyframes join5 {
    0%{
        left:40rem;
        top:25rem;
    }
    4%, 8%, 12%, 16%, 20%, 24%, 28%, 32%, 36%, 40%, 44%, 48%, 52%, 56%, 60%, 64%, 68%,75%,85%,95%{
        transform: rotate(20deg);
    }
    2%, 6%, 10%, 14%, 18%, 22%, 26%, 30%, 34%, 38%, 42%, 46%, 50%, 54%, 58%, 62%, 66%, 70%,80%,90%{
        transform: rotate(0deg);
    }
    71%{
        left:40rem;
        top:27rem;
        transform: rotate(20deg);
    }
    100%{
        transform: rotate(0deg);
        top: 16.6rem;
        left: 14.2rem;
    }
}

#part6{
    height: 4.2rem;
    left: 60rem;
    top:0rem;

}

@keyframes join6 {
    0%{
        left: 60rem;
        top:0rem;
    }
    4%, 8%, 12%, 16%, 20%, 24%, 28%, 32%, 36%, 40%, 44%, 48%, 52%, 56%, 60%, 64%, 68%,75%,85%,95%{
        transform: rotate(20deg);
    }
    2%, 6%, 10%, 14%, 18%, 22%, 26%, 30%, 34%, 38%, 42%, 46%, 50%, 54%, 58%, 62%, 66%, 70%,80%,90%{
        transform: rotate(0deg);
    }
    71%{
        left: 63rem;
        top:0rem;
        transform: rotate(20deg);
    }
    100%{
        transform: rotate(0deg);
        top: 17.4rem;
        left: 26.32rem;
    }
}

#part7{
    height: 10.5rem;
    top: 26.4rem;
    left: 17.32rem;
    transform: rotate(50deg);
}

@keyframes join7 {
    /* 0%{
        left: 20rem;
        top:5rem;
        height: 10.5rem;
        transform: rotateX(75deg)rotateZ(0deg);
    }
    100%{
        top: 26.4rem;
    left: 17.32rem;
    height: 10.5rem;
    z-index: 50;
    transform: rotateX(0deg) rotateZ(1440deg);

} */
0%{
    top: 26.4rem;
    left: 17.32rem;
    transform: rotate(50deg);
}
50%{
    transform: rotate(25deg);
}
55%{
    transform: rotate(30deg);
}

100%{
    transform: rotate(0deg);

}
}

#part8{
    height: 3.5rem;
    left: 20rem;
    top:40rem;

}

@keyframes join8 {
    0%{
        left: 20rem;
        top:40rem;
    }
    4%, 8%, 12%, 16%, 20%, 24%, 28%, 32%, 36%, 40%, 44%, 48%, 52%, 56%, 60%, 64%, 68%,75%,85%,95%{
        transform: rotate(20deg);
    }
    2%, 6%, 10%, 14%, 18%, 22%, 26%, 30%, 34%, 38%, 42%, 46%, 50%, 54%, 58%, 62%, 66%, 70%,80%,90%{
        transform: rotate(0deg);
    }
    71%{
        left: 20rem;
        top:40rem;
        transform: rotate(20deg);
    }
    100%{
        transform: rotate(0deg);
        top: 27.4rem;
    left: 18.82rem;
    }
}

#part9{
    height: 6.5rem;
    left: 12rem;
    top:21rem;

}

@keyframes join9 {
    0%{ 
        left: 12rem;
        top:21rem;
    }
    4%, 8%, 12%, 16%, 20%, 24%, 28%, 32%, 36%, 40%, 44%, 48%, 52%, 56%, 60%, 64%, 68%,75%,85%,95%{
        transform: rotate(20deg);
    }
    2%, 6%, 10%, 14%, 18%, 22%, 26%, 30%, 34%, 38%, 42%, 46%, 50%, 54%, 58%, 62%, 66%, 70%,80%,90%{
        transform: rotate(0deg);
    }
    71%{
        left: 12rem;
        top:23rem;
        transform: rotate(20deg);
    }
    100%{
        transform: rotate(0deg);
        top: 28.8rem;
    left: 18.42rem;
    }
}

#part10{
    height: 2.7rem;
    left: 50rem;
    top:20rem;
}


@keyframes join10 {
    0%{
        left: 50rem;
        top:20rem;
    }
    1%, 3%, 5%, 7%, 9%, 11%, 13%, 15%, 17%, 19%, 21%, 23%, 25%, 27%, 29%, 31%, 33%, 35%, 37%, 39%, 41%, 43%, 45%, 47%, 49%, 51%, 53%, 55%, 57%, 59%, 61%, 63%, 65%, 67%, 69%, 71%, 75%, 85%,95%
    {
        transform: rotate(20deg);
    }
    2%, 4%, 6%, 8%, 10%, 12%, 14%, 16%, 18%, 20%, 22%, 24%, 26%, 28%, 30%, 32%, 34%, 36%, 38%, 40%, 42%, 44%, 46%, 48%, 50%, 52%, 54%, 56%, 58%, 60%, 62%, 64%, 66%, 68%, 70%,80%,90%{
        transform: rotate(0deg);
    }

    25%,35%,45%,65%{
        left: 48rem;
    }

    26%,36%,46%,66%{
        left: 50rem;
    }


    71%{
        left: 50rem;
        top:20rem;
        transform: rotate(20deg);
        height: 2.7rem;
    }

    75%{
        top:23rem;
        height: 3.5rem;

    }
    98%{
        height: 3.5rem;
    }
    100%{
        transform: rotate(0deg);
        top: 24.1rem;
        left: 19.32rem;
        height: 2.7rem;
    }
}


#part1.in-view{
    animation: join1 3s 3s forwards;
}

#part2.in-view{
    animation: join2 3s forwards;
}

#part3.in-view{
    animation: join3 4.5s 5s forwards;
}

#part4.in-view{
    animation: join4 3.5s 3s forwards;
}

#part5.in-view{
    animation: join5 3.8s 3s forwards;
}

#part6.in-view{
    animation: join6 2.5s  3s forwards;
}

#part7.in-view{
    animation: join7 5s  3s  forwards;
}

#part8.in-view{
    animation: join8 3.6s  3s forwards;
}

#part9.in-view{
    animation: join9 3.7s  3s forwards;
}

#part10.in-view{
    animation: join10 10s  4s forwards;
}
