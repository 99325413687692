@import url("https://fonts.googleapis.com/css2?family=Italiana:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaisei+HarunoUmi:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-italiana: Italiana;
  --font-inter: Inter;
  --font-kaisei-harunoumi: "Kaisei HarunoUmi";

  /* font sizes */
  --font-size-11xl: 30px;
  --font-size-61xl: 80px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-midnightblue: #0f1b61;
}
