

.button-icon {
  display: flex;
  border: 3px #fff solid;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  margin-top: 2rem;
}

.icon {
  background-color: #fff;
  padding: 10px 10px 5px 10px;
}

.icon svg {
  width: 25px;
  height: 25px;
}

.cube {
  transition: all 0.4s;
  transform-style: preserve-3d;
  width: 250px;
  height: 20px;
}

.button-icon:hover {
  border-color: #5e43f3;
}

.button-icon:hover .cube {
  transform: rotateX(90deg);
}

.side {
  position: absolute;
  height: 47px;
  width: 250px;
  display: flex;
  font-size: 0.8em;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
}

.top {
  background: #5e43f3;
  color: #fff;
  height: 53px;
  transform: rotateX(-90deg) translate3d(0, 13.5px, 2em);
}

.front {
  background: #222229;
  color: #fff;
  transform: translate3d(0, 0, 1em);
}

@media screen and (max-width:768px){
  .cube,.side{
    width: 50vw;
  }
}